'use client';

import { FullStory } from '@fullstory/browser';
import { type ReactNode, useEffect, useMemo } from 'react';
import { isProd } from '../../common/util/environmentConfig';
import { useMsalAccount } from '../../contexts/TokenContext/useMsalAccount';
import type { FullStoryIdentity } from './FullStoryIdentity';
import { useFullStoryReady } from './useFullStoryReady';

function useFullStoryIdentity(): FullStoryIdentity {
	// Get the account from MSAL
	const account = useMsalAccount();

	// Keep track of the parts of the account info that we need to send to FullStory
	const identity = useMemo(() => {
		const uid = account?.homeAccountId;
		if (!uid) {
			return undefined;
		}

		const email = account.username;
		if (!email) {
			return;
		}
		const displayName = account.name;

		const newIdentity: FullStoryIdentity = {
			anonymous: false,
			uid: uid,
			properties: {
				displayName: displayName,
				email: email,
			},
		};
		return newIdentity;
	}, [account]);

	return identity;
}

export function FullStoryUserLogger(): ReactNode {
	// Keep track of the parts of the account info that we need to send to FullStory
	const identity = useFullStoryIdentity();
	const [fullStoryReady] = useFullStoryReady();

	// If the parts we care about change, send that fo FullStory
	useEffect(() => {
		// No data
		if (!identity) {
			return;
		}
		if (!fullStoryReady) {
			return;
		}

		if (!isProd) {
			console.log('Logging user to FullStory: ', identity.properties.email);
		}

		// Log user information to FullStory
		FullStory('setIdentity', identity);
	}, [identity, fullStoryReady]);

	return null;
}
