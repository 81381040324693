import { replaceTokens } from '@dte/otw/utils/core/src/strings/replaceTokens';
import { isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { defaultTimeZone } from '../defaultTimeZone';

// Months in AP Style
const monthSubstitutions: Record<string, string> = {
	January: 'Jan.',
	February: 'Feb.',
	August: 'Aug.',
	September: 'Sept.',
	October: 'Oct.',
	November: 'Nov.',
	December: 'Dec.',
};

function includesShortMonth(dateFormat: string): boolean {
	return dateFormat.includes('MMM') && !dateFormat.includes('MMMM');
}

function abberviateMonths(dateString: string): string {
	return replaceTokens(dateString, monthSubstitutions);
}

export interface FormatInTzOptions {
	useAPStyleShortMonth?: boolean;
	replaceTodayWithString?: boolean;
	replaceNoonWithString?: boolean;
	replaceMidnightWithString?: boolean;
}

const formattingDefaults: FormatInTzOptions = {
	useAPStyleShortMonth: true,
	replaceTodayWithString: false,
	replaceNoonWithString: false,
	replaceMidnightWithString: false,
};

export function formatInTz(
	date: Date,
	dateFormat: string,
	options?: FormatInTzOptions,
	timeZone: string = defaultTimeZone,
): string {
	if (!isValid(date)) {
		return undefined;
	}

	let formatToUse = dateFormat;

	const mergedOptions: FormatInTzOptions = {
		...formattingDefaults,
		...options,
	};

	// Determine if we need custom logic to replace short months
	const shortMonth = mergedOptions.useAPStyleShortMonth && includesShortMonth(formatToUse);

	// If using short months and the date format contains 'MMMM', start by formatting the date with the full month name (then do a replacement with the abbreviation afterwards)
	if (shortMonth) {
		formatToUse = formatToUse.replace('MMM', 'MMMM');
	}

	let formatted: string;
	try {
		formatted = formatInTimeZone(date, timeZone, formatToUse);

		// Go back and replace the full month name with the abbreviated version
		if (shortMonth) {
			formatted = abberviateMonths(formatted);
		}
	} catch {
		// Unable to format date
	}

	return formatted;
}

export function formatEST(date: Date, dateFormat: string, options?: FormatInTzOptions): string {
	return formatInTz(date, dateFormat, options, 'America/New_York');
}
