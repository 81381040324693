import { equalsIgnoreCase } from '@dte/otw/utils/core/src/strings/equalsIgnoreCase';
import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

type Validator = (value: string) => boolean;

function isValidString(validator: Validator, value: string): boolean {
	if (value === undefined) {
		return false;
	}

	// No validator defined, so we assume it's valid
	if (typeof validator !== 'function') {
		return true;
	}

	return true;
}

function getValidString(value: string, validator?: Validator, defaultValue?: string): string {
	let normalized = normalizeValue(value);

	if (['undefined', 'null'].includes(normalized)) {
		normalized = undefined;
	}

	if (!isValidString(validator, normalized)) {
		return defaultValue;
	}

	return normalized;
}

export function useValidatedStringQueryParam(name: string, validator?: Validator, defaultValue?: string): string {
	const searchParams = useSearchParams();

	// Case insensitive match for the query parameter
	const paramValue = useMemo(() => {
		const keys = searchParams?.keys();
		if (!keys) {
			return undefined;
		}

		for (const key of keys) {
			if (equalsIgnoreCase(name, key)) {
				return searchParams.get(key);
			}
		}
		return undefined;
	}, [name, searchParams]);

	const validatedValue = useMemo(() => {
		return getValidString(paramValue, validator, defaultValue);
	}, [defaultValue, paramValue, validator]);

	return validatedValue;
}
