import { lowerCaseValue } from './lowerCaseValue';

export function normalizeSearchQuery(query: string): string {
	// Normalize
	let normalized = lowerCaseValue(query);

	if (normalized) {
		// Replace commas with spaces
		normalized = normalized.replaceAll(',', ' ');

		// Consolidate spaces
		normalized = normalized.replace(/\s+/g, ' ');
	}

	return normalized;
}
