import { addSearchParamsToUrl } from '@dte/otw/azure-functions/src/common/util/network/url/addSearchParamsToUrl';
import { normalizeSearchQuery } from '@dte/otw/utils/core/src/strings/normalizeSearchQuery';

export function getSearchURL(query: string): string {
	// Normalize the query value so we don't trigger updates when typing spaces
	const searchQuery = normalizeSearchQuery(query);
	if (!searchQuery) {
		return undefined;
	}

	let url = '/authenticated/data/search';
	url = addSearchParamsToUrl(url, {
		query: searchQuery,
	});

	return url;
}
