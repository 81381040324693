import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';

export function normalizePath(value: string): string {
	let normalized = normalizeValue(value);

	// Remove trailing slash
	if (normalized?.endsWith('/')) {
		normalized = normalized.slice(0, -1);
	}

	return normalized;
}
