import { formatDigits } from '@dte/otw/utils/core/src/numbers/formatDigits';

export function formatCount(value: number): string {
	if (value === undefined) {
		return '(count not available)';
	}

	const formatted = formatDigits(0).format(value);
	return formatted;
}
